

import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import "leaflet/dist/leaflet.css";

const Bms = () => {
    const [totalVoltage, setTotalVoltage] = useState('0.00');
    const [current, setCurrent] = useState('0.00');
    const [cell, setCell] = useState(['0','0','0','0','0','0','0','0']);

    useEffect(() => {
        const storedToken = localStorage.getItem('token_ws');

        if (!storedToken) {
            const input = prompt("Please enter your token_ws:");
            if (input) {
                localStorage.setItem('token_ws', input);
            }
        }

        const mqttUrl = 'wss://skyapps.id/mqtt';
        const mqttClient = mqtt.connect(mqttUrl, {
            clientId: 'web_' + Math.random().toString(36).slice(2, 2 + 6),
            username: 'web',
            password: localStorage.getItem('token_ws'),
        });

        mqttClient.on('connect', () => {
            mqttClient.subscribe('jk-bms/sensor/jk-bms_total_voltage/state', (err) => {
                if (err) {
                    console.error('Subscription error:', err);
                } else {
                    console.log('Subscribed to topic');
                }
            });

            mqttClient.subscribe('jk-bms/sensor/jk-bms_current/state', (err) => {
                if (err) {
                    console.error('Subscription error:', err);
                } else {
                    console.log('Subscribed to topic');
                }
            });

            for (let i = 0; i <= 8; i++) {
                mqttClient.subscribe(`jk-bms/sensor/jk-bms_cell_voltage_${i}/state`, (err) => {
                    if (err) {
                        console.error('Subscription error:', err);
                    } else {
                        console.log('Subscribed to topic');
                    }
                });
            }
        });

        mqttClient.on('message', (topic, payload) => {
            console.log(`Received message from topic ${topic}:`, payload.toString());
            if (topic.includes('total_voltage')) {
                setTotalVoltage(payload.toString())
            }

            if (topic.includes('current')) {
                setCurrent(payload.toString())
            }

            if (topic.includes('cell_voltage_1')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[0] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_2')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[1] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_3')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[2] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_4')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[3] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_5')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[4] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_6')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[5] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_7')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[6] = payload.toString();
                    return updatedCell;
                  });
            }

            if (topic.includes('cell_voltage_8')) {
                setCell((prevCell) => {
                    const updatedCell = [...prevCell];
                    updatedCell[7] = payload.toString();
                    return updatedCell;
                  });
            }

        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
        });

        return () => {
            mqttClient.end();
        };
    }, []);

    return (
        <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">JK-BMS</h1>
            <div className="text-xl">Voltage: {totalVoltage} ~ Current: {current}</div>
            Cell 1: {cell[0]}
            <br />
            Cell 2: {cell[1]}
            <br />
            Cell 3: {cell[2]}
            <br />
            Cell 4: {cell[3]}
            <br />
            Cell 5: {cell[4]}
            <br />
            Cell 6: {cell[5]}
            <br />
            Cell 7: {cell[6]}
            <br />
            Cell 8: {cell[7]}
            <br />
            <br />
        </div>
    );
};

export default Bms;
